import React from "react";
import theme from "theme";
import { Theme, Text, Box, List, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"tandc"} />
		<Helmet>
			<title>
				Terms and Conditions
			</title>
			<meta name={"description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:title"} content={"Brain Rank IQ Test"} />
			<meta property={"og:description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar />
		<Section>
			<Box as="h2" margin="20px 0 20px 0">
				<Text as="h1" font="--headline1">
					Terms and Conditions
				</Text>
			</Box>
			<Text font="--base">
				Welcome to IQ Researches!
			</Text>
			<Text font="--base">
				These terms and conditions outline the rules and regulations for the use of Wawedropper LTD's Website, located at
		iqresearches.com.
			</Text>
			<Text font="--base">
				By accessing this website we assume you accept these terms and conditions. Do not continue to use IQ Researches if
		you do not agree to take all of the terms and conditions stated on this page.
			</Text>
			<Text font="--base">
				The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all
		Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's
		terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties",
		or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of
		payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the
		express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in
		accordance with and subject to, prevailing law of gb. Any use of the above terminology or other words in the
		singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to
		same.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					Definition of "IQ Researches"
				</Text>
			</Box>
			<Text as="strong" font="--base">
				In these Terms and Conditions, "IQ Researches" (hereinafter referred to as "IQR") is the project name for the collection of self-improvement related products and services developed, maintained, and offered by Wavedropper LTD. The reference to "IQ Researches" or "IQR" throughout this document shall include all such products and services under the Wavedropper LTD umbrella.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					Cookies
				</Text>
			</Box>
			<Text font="--base">
				We employ the use of cookies. By accessing IQ Researches, you agreed to use cookies in agreement with the
		Wawedropper LTD's Privacy Policy.
			</Text>
			<Text font="--base">
				Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our
		website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our
		affiliate/advertising partners may also use cookies.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					License
				</Text>
			</Box>
			<Text font="--base">
				Unless otherwise stated, Wawedropper LTD and/or its licensors own the intellectual property rights for all material
		on IQ Researches. All intellectual property rights are reserved. You may access this from IQ Researches for your own
		personal use subjected to restrictions set in these terms and conditions.
			</Text>
			<List>
				<Text margin="0px 0px 0px 0px" font="--base">
					Republish material from IQ Researches
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base">
					Sell, rent or sub-license material from IQ Researches
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base">
					Reproduce, duplicate or copy material from IQ Researches
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base">
					Redistribute content from IQ Researches
				</Text>
			</List>
			<Text font="--base">
				This Agreement shall begin on the date hereof.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					Hyperlinking to our Content
				</Text>
			</Box>
			<Text font="--base">
				The following organizations may link to our Website without prior written approval:
			</Text>
			<List font="--base">
				<Text margin="0px 0px 0px 0px">
					Government agencies;
				</Text>
				<Text margin="0px 0px 0px 0px">
					Search engines;
				</Text>
				<Text margin="0px 0px 0px 0px">
					News organizations;
				</Text>
				<Text margin="0px 0px 0px 0px">
					Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of
			other listed businesses; and
				</Text>
				<Text margin="0px 0px 0px 0px">
					System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity
			fundraising groups which may not hyperlink to our Web site.
				</Text>
			</List>
			<Text font="--base">
				These organizations may link to our home page, to publications or to other Website information so long as the link:
		(a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking
		party and its products and/or services; and (c) fits within the context of the linking party's site.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					iFrames
				</Text>
			</Box>
			<Text font="--base">
				Without prior approval and written permission, you may not create frames around our Webpages that alter in any way
		the visual presentation or appearance of our Website.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					Content Liability
				</Text>
			</Box>
			<Text font="--base">
				We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us
		against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted
		as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other
		violation of, any third party rights.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					Reservation of Rights
				</Text>
			</Box>
			<Text font="--base">
				We reserve the right to request that you remove all links or any particular link to our Website. You approve to
		immediately remove all links to our Website upon request. We also reserve the right to amen these terms and
		conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and
		follow these linking terms and conditions.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					Removal of links from our website
				</Text>
			</Box>
			<Text font="--base">
				If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any
		moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
			</Text>
			<Text font="--base">
				We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy;
		nor do we promise to ensure that the website remains available or that the material on the website is kept up to
		date.
			</Text>
			<Box as="h3" font="--headline3">
				<Text as="h2">
					Disclaimer
				</Text>
			</Box>
			<Text font="--base">
				To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
		relating to our website and the use of this website. Nothing in this disclaimer will:
			</Text>
			<List font="--base">
				<Text margin="0px 0px 0px 0px">
					limit or exclude our or your liability for death or personal injury;
				</Text>
				<Text margin="0px 0px 0px 0px">
					limit or exclude our or your liability for fraud or fraudulent misrepresentation;
				</Text>
				<Text margin="0px 0px 0px 0px">
					limit any of our or your liabilities in any way that is not permitted under applicable law; or
				</Text>
				<Text margin="0px 0px 0px 0px">
					exclude any of our or your liabilities that may not be excluded under applicable law.
				</Text>
			</List>
			<Text font="--base">
				The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject
		to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities
		arising in contract, in tort and for breach of statutory duty.
			</Text>
			<Text font="--base">
				As long as the website and the information and services on the website are provided free of charge, we will not be
		liable for any loss or damage of any nature.
			</Text>
		</Section>
		<Components.Footer>
			<Override slot="text1">
				© 2024 IQResearches by WaveDropper LTD. All rights reserved.
			</Override>
			<Override slot="linkBox" />
			<Override slot="box3" display="none" />
		</Components.Footer>
		<Box min-width="100px" min-height="100px" />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://iqresearches.com/tandc/"} rel={"canonical"} place={"endOfHead"} rawKey={"661e64976fbe3d436bb58021"} />
		</RawHtml>
	</Theme>;
});